<template>
  <div>
    <a-card
      v-if="filterData"
      :bordered="false"
      class="header-solid h-full responsive-test"
      style="margin-top: 1rem"
      :bodyStyle="{ padding: 0 }"
    >
      <template #title>
        <a-row style="width: 100%">
          <div style="display: flex; justify-content: space-between">
            <a-input-search
              placeholder="Search by exercise name"
              v-model="txtSearch"
              style="width: 300px"
            />
            <div style="text-align: end">
              <!-- <v-btn color="primary" dark @click="handleSubmit"
                >Tạo bài tập
              </v-btn> -->
            </div>
          </div>
        </a-row>
      </template>
      <a-table
        :columns="columns"
        :data-source="filterData"
        :pagination="{
          pageSize: 10,
          total: this.listTest.length,
        }"
        rowKey="_id"
        style="padding: 10px"
      >
        <template slot="testName" slot-scope="record">
          <h6>{{ isBookMode ? record.bookTestName : record.testName }}</h6>
          <p v-if="!isBookMode" style="color: gray">
            {{ record.testDescription }}
          </p>
        </template>

        <template
          v-if="userProfile.role === 'teacher'"
          slot="totalStudents"
          slot-scope="totalStudents"
        >
          <h6>{{ totalStudents }}</h6>
        </template>
        <template
          v-if="userProfile.role === 'teacher'"
          slot="createdUser"
          slot-scope="createdUser"
        >
          <div class="table-avatar-info">
            <a-avatar shape="square" :src="createdUser.avatar" />
            <div class="avatar-info">
              <h6>{{ createdUser.name }}</h6>
            </div>
          </div>
        </template>
        <template slot="testType" slot-scope="record">
          <a-tag
            class="tag-status ant-tag-primary"
            v-if="record.testType === 'listening'"
          >
            Listening
          </a-tag>
          <a-tag class="tag-status ant-tag-danger" v-else> Reading </a-tag>
        </template>
        <template slot="totalAttempts" slot-scope="record">
          <h6>{{ record.listKeys.length }}</h6>
        </template>
        <template slot="latestScore" slot-scope="record">
          <h6>{{ record.lastScore }}</h6>
        </template>
        <template slot="action" slot-scope="record">
          <a-dropdown>
            <a class="icon-more" @click="(e) => e.preventDefault()">
              <a-icon
                type="more"
                style="font-size: 20px; transform: rotate(90deg)"
            /></a>
            <a-menu slot="overlay">
              <a-menu-item
                v-if="userProfile.role === 'teacher'"
                @click="viewListStudentAnswers(record)"
              >
                <a-icon type="eye" />
                View list submitted
              </a-menu-item>
              <a-menu-item
                v-if="userProfile.role === 'teacher'"
                @click="viewDetailTest(record)"
              >
                <a-icon type="eye" />
                See test
              </a-menu-item>
              <a-menu-item
                v-if="userProfile.role === 'teacher'"
                @click="editTest(record)"
              >
                <a-icon type="edit" />
                Edit test
              </a-menu-item>
              <a-menu-item
                v-if="userProfile.role === 'teacher'"
                @click="doTest(record)"
              >
                <a-icon type="form" /> Do test
              </a-menu-item>
              <a-menu-item
                v-if="userProfile.role === 'teacher'"
                @click="handleViewStats(record)"
              >
                <a-icon type="upload" /> View stats
              </a-menu-item>

              <a-menu-item
                v-if="userProfile.role === 'teacher'"
                @click="handleViewStatsByStudent(record)"
              >
                <a-icon type="message" /> View stats by student
              </a-menu-item>
              <a-menu-item @click="handleDownloadAttachment(record)">
                <a-icon type="download" /> Download Attachments
              </a-menu-item>
              <a-menu-item
                v-if="userProfile.role === 'teacher'"
                @click="confirmDelete(record)"
              >
                <a-icon type="delete" style="color: red" />
                <span style="color: red">Delete test from class</span>
              </a-menu-item>

              <a-menu-item
                v-if="userProfile.role !== 'teacher'"
                @click="doTest(record)"
              >
                <a-icon type="form" /> Do test
              </a-menu-item>
              <a-menu-item
                v-if="userProfile.role !== 'teacher'"
                @click="viewCurrentStudentAnswers(record)"
              >
                <a-icon type="eye" /> View test results
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </a-table>
    </a-card>
    <TableLoading v-else />
    <a-modal
      title="Add test to class"
      :visible="visibleAddClass"
      :confirm-loading="addClassLoading"
      style="width: 90vw !important"
      @ok="handleAddTestToClass"
      @cancel="() => (visibleAddClass = false)"
    >
      <div style="padding: 10px">
        <b>List of classes</b>
        <a-select
          mode="multiple"
          :size="size"
          placeholder="Select a class to add assignments"
          style="width: 100%; margin-top: 1rem"
          v-model="selectedClasses"
        >
          <a-select-option v-for="item in listClass" :key="item._id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
    <a-modal
      title="Remove test from class"
      :visible="visibleDeleteTest"
      :confirm-loading="deleteTestLoading"
      ok-text="Confirm"
      cancel-text="Cancel"
      @ok="handleDeleteTest"
      @cancel="() => (visibleDeleteTest = false)"
    >
      Are you sure to remove test from this class?
    </a-modal>
    <a-modal
      title="Remove result from test"
      :visible="visibleDeleteQuiz"
      :confirm-loading="deleteQuizLoading"
      ok-text="Confirm"
      cancel-text="Cancel"
      @ok="handleDeleteQuiz"
      @cancel="() => (visibleDeleteQuiz = false)"
    >
      Are you sure to remove result from this test?
    </a-modal>
    <a-modal
      :title="
        userProfile.role === 'teacher'
          ? 'Student test results'
          : 'Your test results'
      "
      :visible="visibleViewStudentKeys"
      class="responsive-implementer"
      :confirm-loading="deleteTestLoading"
      ok-text="Confirm"
      width="90vw"
      cancel-text="Cancel"
      @cancel="() => (visibleViewStudentKeys = false)"
    >
      <template slot="footer">
        <a-button key="back" @click="() => (visibleViewStudentKeys = false)">
          Back to class
        </a-button>
      </template>
      <TableLoading v-if="studentKeyLoading" />
      <a-table
        style="max-height: 85vh; overflow-y: scroll"
        v-else
        :columns="
          userProfile.role === 'teacher'
            ? studentKeycolumns.filter((item) => item.title !== 'Test attempt')
            : studentKeycolumns.filter((item) => item.title !== 'Creater')
        "
        :data-source="data"
      >
        <template slot="index" slot-scope="record">
          <h6>{{ record.index }}</h6>
        </template>
        <template
          v-if="userProfile.role === 'teacher'"
          slot="takerName"
          slot-scope="createdUser"
        >
          <h6>{{ createdUser.name }}</h6>
        </template>
        <template slot="createdAt" slot-scope="createdAt">
          <h6>{{ moment(createdAt).format("DD/MM/YYYY-HH:mm") }}</h6>
        </template>
        <template slot="totalCorrect" slot-scope="record">
          <h6>{{ record.totalCorrect }} / {{ record.totalQuestions }}</h6>
        </template>
        <template slot="action" slot-scope="record">
          <a-menu-item
            v-if="userProfile.role !== 'student'"
            @click="confirmDeleteStudentKey(record)"
            style="display: flex"
          >
            <a-icon
              type="delete"
              style="color: red; margin-top: 5px; cursor: pointer"
            />
          </a-menu-item>
        </template>
        <p
          slot="expandedRowRender"
          slot-scope="record"
          style="margin: 0; height: 200px; overflow-y: scroll"
        >
          <a-table
            :rowKey="makeid(10)"
            style="height: 200px; overflow-y: scroll"
            :pagination="false"
            :columns="
              isShowPoint
                ? innerStudentKeyColumns
                : innerStudentKeyColumnsFiltered
            "
            :data-source="record.studentKeys ? record.studentKeys : []"
          >
            <template slot="index" slot-scope="record">
              <h6>{{ record.index }}</h6>
            </template>
            <template v-if="isShowPoint" slot="testKey" slot-scope="record">
              <h6>{{ record.testKey }}</h6>
            </template>
            <template slot="key" slot-scope="record">
              <h6
                v-if="isShowPoint !== null"
                v-bind:style="[
                  record.isCorrect ? { color: 'green' } : { color: 'red' },
                ]"
              >
                {{ record.key }}
              </h6>
              <h6 v-else>
                {{ record.key }}
              </h6>
            </template>
            <template slot="questionType" slot-scope="record">
              <h6>{{ record.questionType }}</h6>
            </template>
          </a-table>
        </p>
      </a-table>
    </a-modal>
    <a-modal
      title="Student statistics"
      :visible="visibleViewStats"
      class="responsive-implementer"
      :confirm-loading="deleteTestLoading"
      ok-text="Confirm"
      width="90vw"
      cancel-text="Cancel"
      @cancel="() => (visibleViewStats = false)"
    >
      <template slot="footer">
        <a-button key="back" @click="() => (visibleViewStats = false)">
          Back to class
        </a-button>
      </template>
      <TableLoading v-if="statsLoading" />
      <a-table
        v-else
        :columns="statsColumns"
        :data-source="statsData"
        :pagination="false"
        size="small"
        :scroll="{ y: '60vh' }"
      >
        <template slot="index" slot-scope="record">
          <h6>Question {{ record.index }}</h6>
        </template>
        <template
          v-if="userProfile.role === 'teacher'"
          slot="takerName"
          slot-scope="createdUser"
        >
          <h6>{{ createdUser.name }}</h6>
        </template>
        <template slot="percentage" slot-scope="record">
          <h6>{{ record.totalFalse || 0 }} / {{ record.total }}</h6>
        </template>
        <template slot="wrongAnswers" slot-scope="record">
          {{ record.wrongAnswers }}
        </template>
        <template slot="action" slot-scope="record">
          <a-menu-item
            v-if="userProfile.role !== 'student'"
            @click="confirmDeleteStudentKey(record)"
            style="display: flex"
          >
            <a-icon
              type="delete"
              style="color: red; margin-top: 5px; cursor: pointer"
            />
          </a-menu-item>
        </template>
      </a-table>
    </a-modal>

    <a-modal
      title="Student statistics"
      :visible="visibleViewStatsByStudent"
      ok-text="Confirm"
      width="90vw"
      cancel-text="Cancel"
      @cancel="() => (visibleViewStatsByStudent = false)"
    >
      <template slot="footer">
        <a-button key="back" @click="() => (visibleViewStatsByStudent = false)">
          Back to class
        </a-button>
      </template>
      <TableLoading v-if="statsLoading" />
      <a-table
        v-else
        :columns="statsByStudentColumns"
        :data-source="statsByStudentData"
        :pagination="false"
        size="small"
        :scroll="{ y: '60vh' }"
      >
        <template slot="index" slot-scope="record">
          <h6>Question {{ record.index }}</h6>
        </template>
        <template
          v-if="userProfile.role === 'teacher'"
          slot="studentName"
          slot-scope="record"
        >
          <h6>{{ record.studentName }}</h6>
        </template>
        <template slot="status" slot-scope="record">
          <a-tag v-if="record.status" color="green"> Submitted </a-tag>
          <a-tag v-else color="red"> Not Submitted </a-tag>
        </template>
        <template slot="highestScore" slot-scope="record">
          <h6>{{ record.highestScore }}</h6>
        </template>
        <template slot="highestScore" slot-scope="record">
          <h6>{{ record.highestScore }}</h6>
        </template>
        <template slot="lowestScore" slot-scope="record">
          <h6>{{ record.lowestScore }}</h6>
        </template>
        <template slot="totalAnswers" slot-scope="record">
          <h6>{{ record.totalAnswers }}</h6>
        </template>
        <template slot="action" slot-scope="record">
          <a-menu-item
            v-if="userProfile.role !== 'student'"
            @click="confirmDeleteStudentKey(record)"
            style="display: flex"
          >
            <a-icon
              type="delete"
              style="color: red; margin-top: 5px; cursor: pointer"
            />
          </a-menu-item>
        </template>
      </a-table>
    </a-modal>
    <a-modal
      title="Attachments"
      :visible="visibleDownloadAttachment"
      :footer="null"
      @cancel="() => (visibleDownloadAttachment = false)"
    >
      <div style="padding: 10px">
        <a-list bordered>
          <a-list-item
            v-for="file in fileList"
            :key="file.name"
            style="
              padding: 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="display: flex; align-items: center">
              <a-icon
                :type="getFileIcon(file.name)"
                style="margin-right: 10px; color: #1890ff"
              />
              <span>{{ file.name }}</span>
            </div>
            <a-icon
              type="download"
              @click="downloadFile(file)"
              style="cursor: pointer"
            />
          </a-list-item>
        </a-list>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { NotificationMixin } from "../../mixin/Notification";
import { LocalStorageMixin } from "../../mixin/LocalStorage";
import TableLoading from "../../components/Base/TableLoading.vue";
import Test from "../../api/Test";
import StudentKeys from "../../api/studentKey";
import Class from "../../api/Class";
import StudentKey from "../../api/studentKey";
export default {
  mixins: [NotificationMixin, LocalStorageMixin],
  components: {
    TableLoading,
  },

  data() {
    return {
      // Active button for the "Authors" table's card header radio button group.
      authorsHeaderBtns: "all",
      studentKeys: [],
      isBookMode: false,
      isAdd: false,
      isShowPoint: true,
      deleteData: null,
      visibleAddClass: false,
      deleteQuizLoading: false,
      selectedQuizId: null,
      currentTestId: null,
      visibleDeleteQuiz: false,
      confirmLoading: false,
      txtSearch: "",
      studentKeyLoading: false,
      visibleDownloadAttachment: false,
      listTest: null,
      selectedClasses: [],
      fileList: [],
      selectedId: null,
      studentKeycolumns: [
        {
          title: "Test attempt",
          dataIndex: "",
          key: "index",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "Creator",
          dataIndex: "createdUser",
          key: "name",
          scopedSlots: { customRender: "takerName" },
        },
        {
          title: "Date",
          dataIndex: "createdAt",
          key: "createdAt",
          scopedSlots: { customRender: "createdAt" },
        },
        {
          title: "Correct answer",
          dataIndex: "",
          key: "totalCorrect",
          scopedSlots: { customRender: "totalCorrect" },
        },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      statsColumns: [
        {
          title: "Question",
          dataIndex: "",
          key: "index",
          scopedSlots: { customRender: "index" },
          width: 150,
        },
        {
          title: "Percentage",
          dataIndex: "",
          key: "percentage",
          scopedSlots: { customRender: "percentage" },
          sorter: (a, b) => a.totalFalse / a.total - b.totalFalse / b.total,

          width: 150,
        },
        {
          title: "Wrong Answers",
          dataIndex: "",
          key: "wrongAnswers",

          scopedSlots: { customRender: "wrongAnswers" },
        },
      ],
      statsByStudentColumns: [
        {
          title: "Student Name",
          dataIndex: "",
          key: "studentName",
          scopedSlots: { customRender: "studentName" },
          width: 150,
        },
        {
          title: "Status",
          dataIndex: "",
          key: "status",
          scopedSlots: { customRender: "status" },
          sorter: (a, b) => Number(b.status) - Number(a.status), // Sorts true (1) above false (0)
          width: 150,
        },
        {
          title: "Lowest Score",
          dataIndex: "",
          key: "lowestScore",
          scopedSlots: { customRender: "lowestScore" },
          sorter: (a, b) => a.lowestScore - b.lowestScore,
          width: 150,
        },
        {
          title: "Hightest Score",
          dataIndex: "",
          key: "highestScore",
          scopedSlots: { customRender: "highestScore" },
          sorter: (a, b) => a.highestScore - b.highestScore,
          width: 150,
        },
        {
          title: "Total Attempts ",
          dataIndex: "",
          key: "totalAnswers",
          scopedSlots: { customRender: "totalAnswers" },
          sorter: (a, b) => a.totalAnswers - b.totalAnswers,

          width: 150,
        },
      ],
      data: [],
      statsData: [],
      statsByStudentData: [],
      innerStudentKeyColumns: [
        {
          title: "Question",
          dataIndex: "",
          key: "index",
          scopedSlots: { customRender: "index" },
        },

        {
          title: "Your Answer",
          dataIndex: "",
          key: "key",
          scopedSlots: { customRender: "key" },
        },
        {
          title: "Key",
          dataIndex: "",
          key: "testKey",
          scopedSlots: { customRender: "testKey" },
        },
        {
          title: "Question Type",
          dataIndex: "",
          key: "questionType",
          scopedSlots: { customRender: "questionType" },
        },
      ],
      innerStudentKeyColumnsFiltered: [
        {
          title: "Question",
          dataIndex: "",
          key: "index",
          scopedSlots: { customRender: "index" },
        },

        {
          title: "Your Answer",
          dataIndex: "",
          key: "key",
          scopedSlots: { customRender: "key" },
        },
        {
          title: "Question Type",
          dataIndex: "",
          key: "questionType",
          scopedSlots: { customRender: "questionType" },
        },
      ],
      size: "default",
      listClass: [],
      page: 1,
      addClassLoading: false,
      visibleDeleteTest: false,
      deleteTestLoading: false,
      visibleViewStudentKeys: false,
      visibleViewStats: false,
      visibleViewStatsByStudent: false,
      statsLoading: false,
    };
  },
  computed: {
    classId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    Class.getClassById(this.$route.params.id)
      .then((response) => {
        this.loading = false;
        this.isBookMode = response.data.data.bookMode;
      })
      .catch((e) => {
        console.log("e", e);
        this.loading = false;
      });
    Test.getTestByClassId(
      this.classId,
      new URLSearchParams([
        ["limit", 100],
        ["page", this.page],
      ])
    )
      .then((response) => {
        this.listTest = response.data.data.sort((a, b) => {
          // Extracting numeric part from the test name.
          const numA = parseInt(a.testName.split(" ")[1]);
          const numB = parseInt(b.testName.split(" ")[1]);

          // Extracting character part from the test name.
          const charA = a.testName[a.testName.indexOf("-") - 2];
          const charB = b.testName[b.testName.indexOf("-") - 2];

          // If the numeric parts are the same, compare by characters.
          if (numA === numB) {
            return charA.localeCompare(charB);
          }

          // Comparing the numbers for sorting in descending order.
          return numB - numA;
        });
      })
      .catch((e) => {
        console.log(e);
      });
    this.userProfile.role === "teacher" || this.userProfile.role === "admin"
      ? StudentKeys.getStudentKeyByClassId({
          classId: this.classId,
        }).then((res) => {
          this.studentKeys = res.data.data;
          this.listTest = this.listTest.map((item) => {
            return {
              ...item,
              totalStudents: this.studentKeys.filter(
                (key) => key.testId === item._id
              ).length,
            };
          });
        })
      : StudentKeys.getStudentKeyByClassAndStudentId({
          classId: this.classId,
          studentId: this.userProfile.id,
        }).then((res) => {
          this.studentKeys = res.data.data;
          console.log(this.listTest);
          this.listTest = this.listTest.map((item) => {
            const studentKeysByTestId = this.studentKeys.filter(
              (key) => key.testId === item._id
            );
            const sortedStudentKey = this.studentKeys
              .filter((key) => key.testId === item._id)
              .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            console.log(sortedStudentKey);
            return {
              ...item,
              listKeys: studentKeysByTestId,
              lastScore:
                sortedStudentKey && sortedStudentKey.length > 0
                  ? `${sortedStudentKey[0].totalCorrect} /
          ${sortedStudentKey[0].totalQuestions}`
                  : "--",
            };
          });
          console.log(res.data.data);
        });

    Class.getListClass(
      new URLSearchParams([
        ["limit", 1000],
        ["page", this.page],
      ])
    )
      .then((response) => {
        this.listClass = response.data.data.sort((a, b) => {
          if (a.testName && b.testName) {
            return a.testName
              .toLowerCase()
              .localeCompare(b.testName.toLowerCase());
          }
          return -1;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  },
  watch: {
    $route(to, from) {
      this.loading = true;
      Class.getClassById(this.$route.params.id)
        .then((response) => {
          this.loading = false;
          this.isBookMode = response.data.data.bookMode;
        })
        .catch((e) => {
          console.log("e", e);
          this.loading = false;
        });
      Test.getTestByClassId(
        this.classId,
        new URLSearchParams([
          ["limit", 100],
          ["page", this.page],
        ])
      )
        .then((response) => {
          this.listTest = response.data.data.sort((a, b) => {
            // Extracting numeric part from the test name.
            const numA = parseInt(a.testName.split(" ")[1]);
            const numB = parseInt(b.testName.split(" ")[1]);

            // Extracting character part from the test name.
            const charA = a.testName[a.testName.indexOf("-") - 2];
            const charB = b.testName[b.testName.indexOf("-") - 2];

            // If the numeric parts are the same, compare by characters.
            if (numA === numB) {
              return charA.localeCompare(charB);
            }

            // Comparing the numbers for sorting in descending order.
            return numB - numA;
          });
        })
        .catch((e) => {
          console.log(e);
        });
      this.userProfile.role === "teacher" || this.userProfile.role === "admin"
        ? StudentKeys.getStudentKeyByClassId({
            classId: this.classId,
          }).then((res) => {
            this.studentKeys = res.data.data;
            this.listTest = this.listTest.map((item) => {
              return {
                ...item,
                totalStudents: this.studentKeys.filter(
                  (key) => key.testId === item._id
                ).length,
              };
            });
          })
        : StudentKeys.getStudentKeyByClassAndStudentId({
            classId: this.classId,
            studentId: this.userProfile.id,
          }).then((res) => {
            this.studentKeys = res.data.data;
            console.log(this.listTest);
            this.listTest = this.listTest.map((item) => {
              const studentKeysByTestId = this.studentKeys.filter(
                (key) => key.testId === item._id
              );
              const sortedStudentKey = this.studentKeys
                .filter((key) => key.testId === item._id)
                .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
              console.log(sortedStudentKey);
              return {
                ...item,
                listKeys: studentKeysByTestId,
                lastScore:
                  sortedStudentKey && sortedStudentKey.length > 0
                    ? `${sortedStudentKey[0].totalCorrect} /
          ${sortedStudentKey[0].totalQuestions}`
                    : "--",
              };
            });
            console.log(res.data.data);
          });

      Class.getListClass(
        new URLSearchParams([
          ["limit", 1000],
          ["page", this.page],
        ])
      )
        .then((response) => {
          this.listClass = response.data.data.sort((a, b) => {
            if (a.testName && b.testName) {
              return a.testName
                .toLowerCase()
                .localeCompare(b.testName.toLowerCase());
            }
            return -1;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  methods: {
    viewListStudentAnswers(record) {
      this.visibleViewStudentKeys = true;
      this.studentKeyLoading = true;
      this.currentTestId = record._id;
      const payload = {
        classId: this.classId,
        testId: record._id,
      };
      StudentKey.getStudentKeyByClassAndTestId(payload)
        .then((response) => {
          this.data = response.data.data;
          this.studentKeyLoading = false;
          console.log(response.data.test);
          this.isShowPoint = response.data.test.isShowPoint;
        })
        .catch((e) => {
          this.studentKeyLoading = false;
          console.log(e);
        });
    },
    handleDownloadAttachment(record) {
      this.fileList = record.attachments || [];
      this.visibleDownloadAttachment = true;
    },
    handleViewStats(record) {
      this.visibleViewStats = true;
      this.statsLoading = true;
      const payload = {
        classId: this.classId,
        testId: record._id,
      };
      StudentKey.getStudentKeyByClassAndTestId(payload)
        .then((response) => {
          this.statsData = response.data.data.sort(
            (a, b) => b.studentKeys.length - a.studentKeys.length
          )[0].studentKeys;
          this.statsLoading = false;
          const listStudentKeys = response.data.data.reduce(
            (acc, item) => acc.concat(item.studentKeys),
            []
          );
          const total = response.data.data.length;
          this.statsData = this.statsData.map((question) => {
            const wrongAnswers = listStudentKeys.filter(
              (item) => item.index === question.index && !item.isCorrect
            );
            const wrongAnswerContent = Array.from(
              new Set(
                listStudentKeys
                  .filter(
                    (item) =>
                      item.index === question.index &&
                      !item.isCorrect &&
                      Boolean(item.key)
                  )
                  .map((item) => item.key)
              )
            );

            return {
              ...question,
              totalFalse: wrongAnswers.length,
              total: total,
              wrongAnswers: wrongAnswerContent.join(", "),
            };
          });
        })
        .catch((e) => {
          this.statsLoading = false;
          console.log(e);
        });
    },

    handleViewStatsByStudent(record) {
      Class.getClassById(this.$route.params.id).then((response) => {
        const payload = {
          classId: this.classId,
          testId: record._id,
        };
        StudentKey.getStudentKeyByClassAndTestId(payload).then(
          (studentKeyResponse) => {
            this.statsByStudentData = response.data.data.students.map(
              (student) => {
                const scores = studentKeyResponse.data.data.filter(
                  (item) => item.studentId === student._id
                );
                const highestScore =
                  scores.length > 0
                    ? Math.max(...scores.map((item) => item.totalCorrect))
                    : 0;
                const lowestScore =
                  scores.length > 0
                    ? Math.min(...scores.map((item) => item.totalCorrect))
                    : 0;
                return {
                  status: scores.length > 0,
                  studentId: student._id,
                  studentName: student.name,
                  highestScore: highestScore || 0,
                  lowestScore: lowestScore || 0,
                  totalAnswers: scores.length,
                };
              }
            );
            console.log(this.statsByStudentData);
            this.visibleViewStatsByStudent = true;
          }
        );
      });
    },
    confirmDeleteStudentKey(payload) {
      this.visibleDeleteQuiz = true;
      this.selectedQuizId = payload._id;
    },
    getFileIcon(fileName) {
      if (!fileName) {
        return;
      }
      const extension = fileName.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return "file-pdf";
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
          return "file-image";
        case "doc":
        case "docx":
          return "file-word";
        case "xls":
        case "xlsx":
          return "file-excel";
        case "ppt":
        case "pptx":
          return "file-ppt";
        case "zip":
        case "rar":
          return "file-zip";
        case "txt":
          return "file-text";
        default:
          return "file";
      }
    },
    downloadFile(file) {
      window.open(file.url, "_blank");
    },
    handleDeleteQuiz() {
      this.deleteQuizLoading = true;
      StudentKeys.deleteStudentKey(this.selectedQuizId)
        .then((response) => {
          this.openNotificationWithIcon(
            "success",
            "Delete student key from test successfully"
          );
          this.deleteQuizLoading = false;
          this.visibleDeleteQuiz = false;
          const payload = {
            classId: this.classId,
            testId: this.currentTestId,
          };
          StudentKey.getStudentKeyByClassAndTestId(payload)
            .then((response) => {
              this.data = response.data.data;
              this.studentKeyLoading = false;
              console.log(response.data.test);
              this.isShowPoint = response.data.test.isShowPoint;
            })
            .catch((e) => {
              this.studentKeyLoading = false;
            });
        })
        .catch((err) => {
          console.log(err);
          this.openNotificationWithIcon(
            "error",
            "Remove result from test failed"
          );
          this.deleteTestLoading = false;
          this.selectedQuizId = null;
          this.visibleDeleteQuiz = false;
        });
    },
    viewDetailTest(record) {
      this.$router.push({ name: "detailTest", params: { id: record._id } });
    },
    viewCurrentStudentAnswers(record) {
      this.visibleViewStudentKeys = true;
      this.studentKeyLoading = true;
      const payload = {
        classId: this.classId,
        testId: record._id,
      };
      StudentKey.getcurrentStudentKeyByClassAndTestId(payload)
        .then((response) => {
          this.data = response.data.data;
          this.studentKeyLoading = false;
          console.log(response);
          this.isShowPoint = response.data.test.isShowPoint;
        })
        .catch((e) => {
          this.studentKeyLoading = false;
          console.log(e);
        });
    },
    getTotalStudent(id) {
      console.log(id);
      console.log(this.classId);
    },
    editTest(payload) {
      this.$router.push({ name: "editTest", params: { id: payload._id } });
    },
    handleDeleteTest() {
      this.deleteTestLoading = true;
      Test.removeTestFromClass({
        classId: this.classId,
        testId: this.selectedId,
      })
        .then((response) => {
          this.openNotificationWithIcon(
            "success",
            "Delete test from class successfully"
          );
          this.deleteTestLoading = false;
          this.visibleDeleteTest = false;
          Test.getTestByClassId(
            this.classId,
            new URLSearchParams([
              ["limit", 100],
              ["page", this.page],
            ])
          )
            .then((response) => {
              this.listTest = response.data.data;
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((err) => {
          this.openNotificationWithIcon(
            "error",
            "Remove test from failed class"
          );
          this.deleteTestLoading = false;
          this.selectedId = null;
          this.visibleDeleteTest = false;
        });
    },
    addTestToClass(payload) {
      this.visibleAddClass = true;
      this.selectedClasses = payload.classIds;
      this.selectedId = payload._id;
    },
    handleAddTestToClass() {
      this.addClassLoading = true;
      Test.updateClassTest(this.selectedId, { classIds: this.selectedClasses })
        .then((response) => {
          console.log(response);
          this.openNotificationWithIcon("success", "Test update successful");
          this.visibleAddClass = false;
          this.selectedId = null;
          this.selectedClasses = [];
          this.addClassLoading = false;
          Test.getListTest()
            .then((response) => {
              console.log(response);
              this.listTest = response.data.data.sort((a, b) => {
                const numA = parseInt(a.testName.split(" ")[1]);
                const numB = parseInt(b.testName.split(" ")[1]);
                const charA = a.testName[a.testName.indexOf("-") - 2];
                const charB = b.testName[b.testName.indexOf("-") - 2];
                // If the numeric part is the same, then compare by characters.
                if (numA === numB) {
                  return charA.localeCompare(charB);
                }
                // Comp
                // Comparing the numbers for sorting in descending order
                return numB - numA;
                // return a.testName.toLowerCase().localeCompare(b.testName.toLowerCase());
              });
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((err) => {
          this.visibleAddClass = false;
          this.selectedId = null;
          this.selectedClasses = [];
          this.addClassLoading = false;
        });
    },
    confirmDelete(payload) {
      this.visibleDeleteTest = true;
      this.selectedId = payload._id;
    },
    handleSubmit() {
      this.$router.push("/test/add");
    },
    doTest(record) {
      this.$router.push({
        name: "doTest",
        params: { id: this.classId, exerciseId: record._id },
      });
    },
    makeid(length) {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
  computed: {
    classId() {
      return this.$route.params.id;
    },
    filterData() {
      return this.listTest?.filter((row) => {
        const testName = row.testName.toString().toLowerCase();

        return testName.includes(this.txtSearch.toLowerCase());
      });
    },
    userProfile() {
      return JSON.parse(localStorage.getItem("userProfile"));
    },
    columns() {
      return this.userProfile.role === "teacher"
        ? [
            {
              dataIndex: "",
              key: "testName",
              title: "Test",
              scopedSlots: { customRender: "testName" },
            },
            {
              title: "Total submitted",
              dataIndex: "totalStudents",
              key: "totalStudents",
              scopedSlots: { customRender: "totalStudents" },
            },
            {
              title: "Test Type",
              key: "testType",
              dataIndex: "",
              scopedSlots: { customRender: "testType" },
            },
            {
              title: "Created By",
              key: "createdUser",
              dataIndex: "createdUser",
              scopedSlots: { customRender: "createdUser" },
            },
            {
              title: "",
              key: "action",
              scopedSlots: { customRender: "action" },
            },
          ]
        : [
            {
              dataIndex: "",
              key: "testName",
              title: "Test",
              scopedSlots: { customRender: "testName" },
            },
            {
              title: "Latest Score",
              key: "latestScore",
              dataIndex: "",
              scopedSlots: { customRender: "latestScore" },
            },
            {
              title: "Total Attempts",
              key: "totalAttempts",
              dataIndex: "",
              scopedSlots: { customRender: "totalAttempts" },
            },
            {
              title: "Skill(s)",
              key: "testType",
              dataIndex: "",
              scopedSlots: { customRender: "testType" },
            },
            {
              title: "",
              key: "action",
              scopedSlots: { customRender: "action" },
            },
          ];
    },
  },
};
</script>
<style lang="scss" scoped>
.responsive-implementer {
  width: 1400px;
}
@media only screen and (max-width: 1000px) {
  .responsive-test {
    margin-left: 5%;
  }
  .responsive-implementer {
    width: 100vw;
  }
}
@media only screen and (max-width: 400px) {
  .responsive-test {
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 500px) and (orientation: portrait) {
}
</style>
